<template>
    <div>
        <app-header :title="title" :isShowBack="true"></app-header>
        <div class="refund-btn">
            <el-button type="primary" @click="reviewRefund()" v-show="refundStatus == 0 && merchantFlag == true">审核退款</el-button>
        </div>
        <el-dialog top="30vh" width="480px" :visible.sync="reviewDialogVisible"  :close-on-click-modal="false">
            <div class="review-title">审核退款</div>
            <div>
                <div class="review-tips">退款金额<span style="color:red">￥{{refundPrice}}</span>，确定同意退款吗？</div>
                <div style="color:#aaa">该操作不可逆，请谨慎操作。同意退款后，该笔订单将原路退还至用户支付账户。</div>
            </div>
            <div class="review-btn">
                <el-button @click="openRejectDialog()">驳回</el-button>
                <el-button type="primary" @click="agreeRefund()"  :loading="agreeLoading">同意退款</el-button>
            </div>
        </el-dialog>
        <el-dialog top="30vh" width="480px" :visible.sync="rejectDialogVisible"  :close-on-click-modal="false"  @close="closeRejectDialog()">
            <div class="review-title">驳回退款</div>
            <div>
                <div class="review-tips">退款金额<span style="color:red">￥{{refundPrice}}</span>，确定同意退款吗？</div>
                <div style="color:#aaa">该操作不可逆，请谨慎操作。同意退款后，该笔订单将原路退还至用户支付账户。</div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="76px" class="demo-ruleForm">
                    <el-form-item label="驳回原因" prop="refundReason">
                        <el-input type="textarea" v-model="ruleForm.refundReason" placeholder="填写驳回原因" maxlength="24" show-word-limit  :rows="3"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="review-btn">
                <el-button @click="closeRejectDialog()">关闭</el-button>
                <el-button type="danger" @click="rejectRefund()" :loading="rejectLoading">驳回</el-button>
            </div>
        </el-dialog>
        <div style="margin-bottom: 10px;padding-left:22px;margin-top:18px;">支付订单信息</div>
        <div class="detail">
            <div class="content-left">
                <div class="content-box" v-if="!merchantFlag">
                    <div class="title">商家名称</div>
                    <div class="content">{{merchantName}}</div>
                </div>
                <div class="content-box">
                    <div class="title">门店名称</div>
                    <div class="content">{{groupName}}</div>
                </div>
                <div class="content-box">
                    <div class="title">门店位置</div>
                    <div class="content">{{address}}</div>
                </div>
                <div class="content-box">
                    <div class="title">货柜位置</div>
                    <div class="content">{{counterLocation}}</div>
                </div>
                <div class="content-box">
                    <div class="title">货柜MAC</div>
                    <div class="content">{{counterMac}}</div>
                </div>
                <div class="content-box" v-if="merchantFlag">
                    <div class="title">&nbsp;</div>
                    <div class="content">&nbsp;</div>
                </div>
            </div>
            <div class="content-center">
                <div class="content-box">
                    <div class="title">下单时间</div>
                    <div class="content">{{orderCreateTime}}</div>
                </div>
                <div class="content-box">
                    <div class="title">支付订单号</div>
                    <div class="content">{{orderId}}</div>
                </div>
                <div class="content-box">
                    <div class="title">商户平台订单号</div>
                    <div class="content">{{transactionNo}}</div>
                </div>
                <div class="content-box">
                    <div class="title">订单金额(元)</div>
                    <div class="content">{{orderPrice}}</div>
                </div>
                <div class="content-box">
                    <div class="title">支付方式</div>
                    <div class="content">{{payMethod}}</div>
                </div>
            </div>
            <div class="content-right">
                <div class="content-box">
                    <div class="title">支付时间</div>
                    <div class="content">{{payTime}}</div>
                </div>
                <div class="content-box">
                    <div class="title">会员/消费者</div>
                    <div class="content">{{receiverName}}</div>
                </div>
                <div class="content-box">
                    <div class="title">会员/消费者手机号</div>
                    <div class="content">{{phone}}</div>
                </div>
                <div class="content-box">
                    <div class="title">货管员</div>
                    <div class="content">{{counterUserName}}</div>
                </div>
                <div class="content-box">
                    <div class="title">&nbsp;</div>
                    <div class="content">&nbsp;</div>
                </div>
            </div>
        </div>
        <div style="margin-bottom: 10px;padding-left:22px;">退款详情</div>
        <div class="detail">
            <div class="content-left">
                <div class="content-box">
                    <div class="title">退款单号</div>
                    <div class="content">{{refunId}}</div>
                </div>
                <div class="content-box">
                    <div class="title">退款金额（元）</div>
                    <div class="content">{{refundPrice}}</div>
                </div>
                <div class="content-box">
                    <div class="title">申请退款时间</div>
                    <div class="content">{{applyRefundTime}}</div>
                </div>
                <div class="content-box">
                    <div class="title">审核时间</div>
                    <div class="content">{{auditTime}}</div>
                </div>
                <div class="content-box">
                    <div class="title">到账时间</div>
                    <div class="content">{{realRefundTime}}</div>
                </div>
            </div>
            <div class="content-right">
                <div class="content-box">
                    <div class="title">退款状态</div>
                    <div class="content">{{refundStatusStr}}</div>
                </div>
                <div class="content-box" v-if="refundStatus == 3 || refundStatus == 4 ">
                    <div class="title">{{refundStatus==3?'退款失败原因':'驳回原因'}}</div>
                    <div class="content">{{refundReason}}</div>
                </div>
                <div class="content-box" v-else>
                    <div class="title">&nbsp;</div>
                    <div class="content">&nbsp;</div>
                </div>
                <div class="content-box">
                    <div class="title">&nbsp;</div>
                    <div class="content">&nbsp;</div>
                </div>
                <div class="content-box">
                    <div class="title">&nbsp;</div>
                    <div class="content">&nbsp;</div>
                </div>
                <div class="content-box">
                    <div class="title">&nbsp;</div>
                    <div class="content">&nbsp;</div>
                </div>
            </div>
        </div>
        <div>
            <div style="margin-bottom: 10px;padding-left:22px;">退款商品信息</div>
            <el-table :data="commodityList" height="390" style="width: 100%">
                <el-table-column prop="subOrderId" align="center" label="支付子订单号">
                </el-table-column>
                <el-table-column prop="itemName" align="center" label="商品名称">
                </el-table-column>
                <el-table-column prop="doorCode" align="center" label="货柜柜门/货位">
                </el-table-column>
                <el-table-column prop="itemCode" align="center" label="商品编号">
                </el-table-column>
                <el-table-column prop="itemImg" align="center" label="商品图片">
                    <template slot-scope="scope">
                        <img :src="scope.row.itemImg" style="height:100px;" alt="">
                    </template>
                </el-table-column>
                <el-table-column prop="categoryName" align="center" label="商品品类">
                </el-table-column>
                <el-table-column prop="sellPrice" align="center" label="商品售价（元）">
                </el-table-column>
                <el-table-column v-if="notMerchant" prop="supplyPrice" align="center" label="商品经销价（元）">
                </el-table-column>
                <el-table-column v-if="notDealer" prop="costPrice" align="center" label="商品成本价（元）">
                </el-table-column>
                <el-table-column prop="itemDetail" align="center" label="商品详细描述">
                </el-table-column>
            </el-table>
        </div>
        <!-- <el-dialog title="货管员" top="20vh" width="380px" :visible.sync="showMoreCounterUser"
            :close-on-click-modal="false">
            <div class="counterUserResListBox">
                <div class="counterUserResListBox_row" v-for="(item,index) in counterUserResList" :key="index">
                    {{item.realName + '/' + item.phone}}<span v-show="item.isMaster == 1">（主货管员）</span></div>
            </div>
        </el-dialog> -->
    </div>

</template>
<script>
    export default {
        data() {
            let _this = this;
            return {
                seeMoreFlag: false,
                counterUserResList: [],
                // showMoreCounterUser: false,
                title: '退款详情',
                payMethod: '',
                orderCreateTime: '', // 1退款失败  2已退款  3退款中
                orderId: "",
                orderPrice: "",
                receiverName: "",
                phone: "",
                counterMac: "",
                transactionNo: "",
                payTime: "",
                counterUserName: "",
                address: "",
                counterLocation: "",
                refunId: "",
                refundPrice: "",
                refundStatusStr: "",
                refundStatus: "",
                refundReason: "",
                applyRefundTime: "",
                auditTime: "",
                realRefundTime: "",
                commodityList: [],
                groupName: "",
                merchantName: "",
                reviewDialogVisible: false,
                rejectDialogVisible: false,
                refundPrice: '',
                ruleForm: {
                    refundReason: ''
                },
                rules: {
                    refundReason: [
                        { required: true, message: '请填写驳回原因', trigger: 'blur' }
                    ]
                },
                merchantFlag: false,
                agreeLoading: false,
                rejectLoading: false,
                notDealer: true,
                notMerchant: true,
            };
        },
        created() {},
        activated() {
            console.log("detail activated!!");
            this.getOrderInfo();
            this.getCategory().then(values => {
                // this.getRefundInfo(values);
            })
            this.merchantFlag = this.getLoginType('loginType');
            let userInfo = this.cache.getLS("userInfo");
            if(this.merchantFlag && userInfo.merchantType == 3){
                this.notDealer = false
            } else {
                this.notDealer = true
            }
            if(this.merchantFlag && userInfo.merchantType !== 3){
                this.notMerchant = false
            } else {
                this.notMerchant = true
            }
        },
        deactivated() {
            this.reviewDialogVisible = false;
            this.rejectDialogVisible = false;
        },
        mounted() {},

        methods: {
            getOrderInfo() {
                let dto = {
                    refundId: this.$route.query.id
                }
                this.post('mall-service/mall/api/web/query/v1/order-detail',dto, {
                    isUseResponse: true
                }).then(res => {
                    let data = res.data.data;
                    // 支付订单信息
                    if (data.payMethod == 1) {
                        this.payMethod = "微信支付";
                    }
                    // else if (data.payMethod ==5) {
                    //     this.payMethod = "微信支付(使用优惠券)";
                    // }
                    else if (data.payMethod ==4) {
                        this.payMethod = "会员余额支付";
                    }
                    else if (data.payMethod ==6) {
                        this.payMethod = "刷卡支付";
                    }
                    // else if (data.payMethod ==7) {
                    //     this.payMethod = "通联支付";
                    // }
                    this.orderCreateTime = data.orderCreateTime;
                    this.orderId = data.orderId;
                    this.orderPrice = data.orderPrice;
                    this.receiverName = data.receiverName ? data.receiverName : '--';
                    this.phone = data.phone ? data.phone : '--';
                    this.counterMac = data.counterMac;
                    this.transactionNo = data.transactionNo ? data.transactionNo : '--';
                    this.payTime = data.payTime;
                    // if(data.counterUserRes){
                    //     let counterUserResListItem = data.counterUserRes
                    //     this.counterUserName = counterUserResListItem.realName + '/' + counterUserResListItem.phone;
                    // }else{
                    //     this.counterUserName = '--';
                    // }
                    this.counterUserResList = data.counterUserResList ? data.counterUserResList : [];
                    if (data.counterUserResList && data.counterUserResList.length > 0) {
                        if(data.counterUserResList.some(item => item.isMaster == 1)){
                            data.counterUserResList.forEach(item =>{
                                if(item.isMaster == 1){
                                    let counterUserResListItem = item
                                    this.counterUserName = counterUserResListItem.realName + '/' + counterUserResListItem.phone;
                                }
                            })
                        }else{
                            let counterUserResListItem = data.counterUserResList[0];
                            this.counterUserName = counterUserResListItem.realName + '/' + counterUserResListItem.phone;
                        }
                    } else {
                        this.counterUserName = '--';
                    }
                    this.address = data.address ? data.address : '--';
                    this.counterLocation = data.counterLocation ? data.counterLocation : '--';
                    if (data.groupName) {
                        if (data.region) {
                            this.groupName = data.groupName + " " + data.region + data.regionNumber;
                        } else {
                            this.groupName = data.groupName;
                        }
                    } else {
                        this.groupName = '--'; //门店信息
                    }

                    this.merchantName = data.merchantName ? data.merchantName : '--'
                    // 退款详情
                    this.refunId = data.id ? data.id : '--';
                    this.refundPrice = data.refundPrice ? data.refundPrice : '--';
                    this.applyRefundTime = data.applyRefundTime ? data.applyRefundTime : '--';
                    this.auditTime = data.auditTime ? data.auditTime : '--';
                    this.realRefundTime = data.realRefundTime ? data.realRefundTime : '--';
                    this.refundStatusStr = ["待审核","退款中", "已退款", "退款失败","已驳回"][data.state];
                    this.refundStatus = data.state;
                    this.refundReason = data.refundReason ? data.refundReason : '--';
                    // 退款商品信息
                    this.commodityList = data.items;
                });
            },
            // seeMore() {
            //     this.showMoreCounterUser = true;
            // },
            reviewRefund() {
                this.reviewDialogVisible = true
                this.agreeLoading = false
            },
            agreeRefund() {
                this.agreeLoading = true
                let dto = {
                    refundId: this.$route.query.id,
                    storeId: this.cache.getLS('userInfo').merchantId
                }
                this.post("mall-service/mall/api/web/v1/agree-refund",dto,{
                    isUseResponse: true
                }).then(res => {
                    if(res.data.code == 0) {
                        this.$message({
                            message: '审核通过',
                            type: "success"
                        });
                        this.getOrderInfo();
                        this.reviewDialogVisible = false
                    }
                    this.agreeLoading = false
                }).catch(error =>{
                    this.agreeLoading = false
                })
            },
            openRejectDialog() {
                this.rejectDialogVisible = true
                this.rejectLoading = false
            },
            rejectRefund() {
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) {
                        this.rejectLoading = true
                        let dto = {
                            reason: this.ruleForm.refundReason,
                            refundId: this.$route.query.id
                        }
                        this.post("mall-service/mall/api/web/v1/reject-refund",dto,{
                            isUseResponse: true
                        }).then(res => {
                            if(res.data.code == 0) {
                                this.$message({
                                    message: '驳回成功',
                                    type: "success"
                                });
                                this.getOrderInfo();
                                this.reviewDialogVisible = false
                                this.rejectDialogVisible = false
                            }
                            this.rejectLoading = false
                        }).catch(error =>{
                            this.rejectLoading = false
                        })
                    }
                });
            },
            closeRejectDialog(){
                this.rejectDialogVisible = false
                this.$refs['ruleForm'].resetFields();
            }
        }
    };
</script>
<style lang="scss" scoped>
    .detail {
        margin: auto;
        width: 1440px;

        .content-left,.content-center,
        .content-right {
            display: inline-block;
            width: 480px;
        }

        .content-box {
            margin: 20px 0;
            font-size: 15px;
            display: flex;

            .title {
                display: inline-block;
                padding-right: 40px;
                text-align: right;
                width: 200px;
            }

            .content {
                color: #666;
                display: inline-block;
                width: 300px;
                word-wrap: break-word;
            }

            .seeMore {
                color: #30BAC1;
                cursor: pointer;
                padding-left: 10px;
            }

            .describeContent {
                display: inline-block;
                width: 750px;
                word-wrap: break-word;
            }

            .productPicture {
                width: 200px;
                height: 130px;
                margin: 8px;
            }
        }
    }

    .counterUserResListBox {
        height: 180px;
        overflow-y: auto;
    }

    .counterUserResListBox_row {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #EBEEF5;
        padding-left: 57px;
    }
    .review-tips{
        font-size: 18px;
        margin-top: 14px;
        margin-bottom: 10px
    }
    .review-title{
        font-size: 20px;
        font-weight: bold;
    }
    .review-btn{
        display: flex;
        justify-content: end;
        margin-top: 10px;
    }
    .refund-btn{
        text-align: end;
        width: 98%;
    }
</style>